import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import {
  Layout,
  Image,
  SEO,
  Inspiration,
  Card,
  Timer,
  DownloadPdf,
} from '../components'

const IndexPage = ({ data }) => (
  <Layout className="stack">
    <SEO title="Home" />
    <div className="w-24 absolute ml-24 hidden xl:relative">
      <Image fluid={data.ps.logo.fluid} />
    </div>
    <h1 className="title pt-4">Bienvenue chez Portes Services s.a.</h1>
    <section className="relative xl:flex xl:py-24">
      <div className="logo-hormann">
        <Image fluid={data.hormann.childImageSharp.fluid} />
      </div>
      <div className="flex-1 md:pl-5 md:flex-col">
        <h2 className="text-5xl tracking-widest font-black">Dealer Hörmann</h2>
        <p className="text-3xl">
          Nous vendons des produits Hörmann de qualité depuis plus de 20 ans.
        </p>
      </div>
    </section>
    <section className="bg-blue-100 shadow-inner py-6">
      <h2 className="title">Nos produits</h2>
      <div className="flex flex-col mb-4">
        <div className="w-full flex flex-wrap justify-around mb-4">
          <Link
            className="w-full mb-4 md:mb-0 md:w-1/2 max-w-xs lg:max-w-md hover:shadow-2xl hover:opacity-75 cursor-pointer"
            to="/porte-garage"
          >
            <Card
              title="Portes de garage"
              image={<Image fluid={data.sectionnelle.childImageSharp.fluid} />}
            />
          </Link>
          <Link
            className="w-full md:w-1/2 max-w-xs lg:max-w-md hover:shadow-2xl hover:opacity-75 cursor-pointer"
            to="/porte-entree"
          >
            <Card
              title="Portes d'entrée"
              image={<Image fluid={data.entree.childImageSharp.fluid} />}
            />
          </Link>
        </div>
        <div className="w-full flex justify-around">
          <Link
            className="w-full md:w-1/2 max-w-xs lg:max-w-md hover:shadow-2xl hover:opacity-75 cursor-pointer"
            to="/motorisations"
          >
            <Card
              title="Motorisations"
              image={<Image fluid={data.moteur.childImageSharp.fluid} />}
            />
          </Link>
          <Link
            className="w-full md:w-1/2 max-w-xs lg:max-w-md hover:shadow-2xl hover:opacity-75 cursor-pointer"
            to="/porte-interieur"
          >
            <Card
              title="Portes d'intérieur"
              image={<Image fluid={data.interieur.childImageSharp.fluid} />}
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-wrap justify-around">
        {data.productsPDF && (
          <DownloadPdf
            url={data.productsPDF.publicURL}
            name={data.productsPDF.name}
          >
            Télécharger le catalogue
          </DownloadPdf>
        )}
      </div>
    </section>
    {/* <section>
      <h2 className="title">Nos Promotions</h2>
      <p className="text-3xl bold text-center">
        Découvrez comment Hörmann met les points sur les Ö !
      </p>
      <h3 className="title">Chèques Cadeaux</h3>
      <p>
        Laissez nous vos coordonnées pour bénéficer d'un chèque cadeau pour
        profiter d'avantages allant jusqu'à 400 €.
      </p>
      <p>
        Ce chèque cadeau vous permet soit de bénéficier d'accessoires
        gratuitement pour votre nouvelle porte sectionnelle avec motorisation
        d'une valeur de 300 € ou si vous êtes à la recherche une porte d'entrée,
        vous pouvez profiter dès maintenant d'une réduction de 400 € sur votre
        nouvelle porte d'entrée Hörmann en aluminium.
      </p>
      <div className="mb-4 flex flex-col">
        <div className="flex flex-col-reverse sm:flex sm:flex-row justify-center content-center align-middle shadow-lg rounded">
          <Image
            className="w-auto sm:w-1/2"
            fluid={data.chequeCadeauPorteGarage.childImageSharp.fluid}
          />
          <div className="w-full mx-auto px-10 my-2 hidden sm:block">
            <ul className="font-bold flex flex-col justify-evenly h-full">
              <li className="mx-auto text-center">
                Accessoires gratuits à l'achat d'une nouvelle porte sectionnelle
                avec motorisation d'une valeur de 300 €.
              </li>
              <li className="mx-auto">
                Une porte d'entrée Hörmann : c'est choisir pour une qualité
                supérieure !
              </li>
              <li className="mx-auto">
                Réduction de 400 € sur votre nouvelle porte d'entrée Hörmann en
                aluminium
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full flex flex-col-reverse sm:flex sm:flex-row justify-center content-center align-middle shadow-lg rounded mb-5">
          <div className="w-full mx-auto sm:my-auto px-10 my-2" />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <Card
          size="w-auto"
          className="mb-4 pb-0 shadow-lg rounded-lg"
          title="Offres valables jusqu'au 30 avril 2021 inclus"
        >
          <Timer className="text-center" dateEnd="2021-04-30 23:59:59" />
        </Card>
      </div>
    </section> */}
    <section>
      <Inspiration className="shadow px-6 mt-0" />
    </section>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query {
    hormann: file(relativePath: { eq: "Hormann-Logo.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sectionnelle: file(relativePath: { eq: "SectionnelleBANNER.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    entree: file(relativePath: { eq: "EntreeBANNER.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    moteur: file(relativePath: { eq: "moteur-garage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interieur: file(
      relativeDirectory: { eq: "" }
      name: { eq: "portes-interieur" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ps: file(
      relativeDirectory: { eq: "" }
      name: { eq: "logo_portes-services" }
    ) {
      logo: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chequeCadeauPorteGarage: file(
      relativeDirectory: { eq: "" }
      name: { eq: "cheque-cadeau_portes-sectionnelles" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chequeCadeauPorteEntree: file(
      relativePath: { eq: "cheque-cadeau_portes-entrees.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productsPDF: file(
      name: { eq: "catalogue_hormann" }
      extension: { eq: "pdf" }
      relativeDirectory: { eq: "" }
    ) {
      publicURL
      name
    }
  }
`

export default IndexPage
